'use client'

import { Box, Flex, Image,  Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import formatter from '@/utils/formatter';
import { useRouter } from 'next/navigation';

import Link from 'next/link';
const DevelopmentCard = props => {
  
  const { push } = useRouter();



const handlePropertyClick=(id)=>{
  
  const encodedName = id.normalize("NFD") // Normalizar caracteres con diacríticos
  .replace(/[\u0300-\u036f]/g, "") // Eliminar diacríticos  .replace(/ /g, "-");
  .replace(/-/g, "_")
  .replace(/ /g, "-")
  .replace(/\//g, "*"); 

window.open(`emprendimientos/${encodedName}`, '_blank');

} 


  return (

   
    <Box
   
    onClick={() => handlePropertyClick(props.project.developmentName)}
 cursor={'pointer'}
 m={{
  base: '15px 5px',
  sm: '20px',
  customSmMd:'15px 3px',
  md:'20px'

}}
     position={'relative'}
     overflow={'hidden'}
     borderRadius={'4px'}

     w={{
      base: '340px',
      sm: '440px',
      customSmMd:'300px',
      md: '300px',
      lg: '310px',
      xl: '350px',
    }}
     height={'400px'}
     >
     <Image
       src={props.arrayPictures ? props.arrayPictures[0] : ''}
       alt={'imagen propiedad'}
       w={{
        base: '340px',
        customSmMd:'300px',
        sm: '440px',
        md: '300px',
        lg: '310px',
        xl: '350px',
      }}
       minW={'150px'}
       maxW={'450px'}
       height={'275px'}
       objectFit="cover"
     />
     <Box
      
       bg={'rgba(40, 40, 40, 0.8)'}
      
       position="absolute"
       color={'white'}
       height={'40px'}
       w={'100%'}
       left={0}
      padding={'5px 20px'}
       top={'235px'}
       display={'flex'}
alignItems={'center'}
justifyContent={'space-between'}
       ><Box>
        <Text
         fontFamily="Heebo, sans-serif"
         fontWeight={300}
         marginTop={'2px'}
         fontSize={'11px'}
         >ESTADO DE  </Text>
       <Text
         fontFamily="Heebo, sans-serif"
         fontWeight={300}
         fontSize={'11px'}
         >
           CONSTRUCCIÓN
           </Text>
        {/* {props.project.minorPricesUSD?`DESDE USD ${props.project.minorPricesUSD}`: 'CONSULTAR'}
       </Text>
        {/*  <Text
         fontFamily="Heebo, sans-serif"
         fontWeight={300}
         fontSize={'13px'}
         >
           {`${formatter.roomsOptions(props.project.rooms)} AMB.`}
        {/* {props.project.minorPricesUSD?`DESDE USD ${props.project.minorPricesUSD}`: 'CONSULTAR'}
       </Text>*/}
       </Box>
       <Text
         fontFamily="Heebo, sans-serif"
         fontWeight={400}
         fontSize={'20px'}
        >
           {props.project.steps}
     
       </Text>
     </Box>

     <Box
       textAlign={'start'}
       bg={'#656664'}
       padding={'8px'}
       borderTop="3px solid white"
       height={'130px'}
       display={'flex'}
       flexDirection={'column'}
       justifyContent={'space-between'}>
       <Box>
       <Text
         fontFamily="Heebo, sans-serif"
         //textShadow={'1px 1px 3px rgba(0, 0, 0, 0.6)'}
         lineHeight={1.1}
         fontSize={'14px'}
         p={'2px 0px 3px 5px'}
         color={'white'}>
        
           {props.project?.developmentName ? props.project?.developmentName:props.project?.fakeAddress}
    
       </Text>
       <Text
             fontFamily="Heebo, sans-serif"
             paddingLeft={'5px'}
             fontSize={'12px'}
             fontWeight={300}
             //textShadow={'1px 1px 3px rgba(0, 0, 0, 0.6)'}
             color={'white'}>
               <span>
               {formatter.cleanLocation(props.project?.fullLocation)}

           
            </span>
             {/* <span>
               {props.project.location?.zone
                 ? props.project.location.zone 
                 : ''}
                  {props.project.location?.city ?  '   |   ' + props.project.location?.city 
             : ''
           }
               
               {props.project.location?.country
                   ?  '   |   '+ props.project.location?.country
                   : ''}
             </span>*/} 
           </Text>
          </Box>
    

         <Box
        
           display={'flex'}
    alignItems={'flex-end'}
           flexDirection={'column'}
           borderRadius={'5px'}
          paddingRight={'10px'}
          marginTop={'10px'}
   
          >

           <Text fontFamily="Heebo, sans-serif"
             color={'white'}
             fontWeight={400}
             fontSize={'17px'}>{props.project.minorPricesUSD?`DESDE U$S ${formatter.formatUSD(props.project.minorPricesUSD)} m² `: 'CONSULTAR'}</Text>
       
         
      <Text
         textAlign={'end'}

            fontFamily="Heebo, sans-serif"
          
            fontSize={{ base:'11px', sm:'12px', }}
            fontWeight={300}
           // textShadow={'1px 1px 3px rgba(0, 0, 0, 0.6)'}
            color={'white'}>
               <span> {formatter.replaceWord(props.project.delivery)}</span>
            
            </Text>   
         </Box>
   </Box>
   </Box>

    
 
  );
};

export default DevelopmentCard;
